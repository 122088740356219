import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "joelHayhurst",
  "categories": ["docker", "ruby", "lambda"],
  "date": "2020-07-13",
  "path": "/blog/lambda-layer-dependencies",
  "summary": "We describe how we built and published AWS Lambda dependencies",
  "title": "Ruby on AWS Lambda: Layer Dependencies",
  "image": "./lambda_deps_ogp.png",
  "seo": {
    "og": {
      "type": "article",
      "title": "AWS Lambda Dependencies",
      "description": "We describe how we built and published AWS Lambda dependencies"
    },
    "twitter": {
      "card": "summary_large_image",
      "site": "@hintmedia"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><em parentName="p">{`This article is part of our Ruby on AWS Lambda blog series. A recent project had us migrating an existing PDF document processing system from Rails Sidekiq to AWS Lambda. The processing includes OCR, creating preview images, splicing the PDF, and more. Moving to Lambda reduced processing time by 300 times in some cases.`}</em></p>
    <p><em parentName="p">{`This series of articles will serve less as a step-by-step process to get OCR serverless infrastructure up and running and more of a highlight reel of our "Aha!" moments. In part one, we talk about creating a AWS Lambda Layer with Docker. Check out the other posts in the series:`}</em></p>
    <ul>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-planning-and-architecture"
          }}>{`Planning & Architecture`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/aws-lambda-ship-it"
          }}>{`Package & Ship It!`}</a></em></li>
      <li parentName="ul"><em parentName="li"><a parentName="em" {...{
            "href": "/blog/lambda-and-active-storage"
          }}>{`Integrating with Active Storage`}</a></em></li>
    </ul>
    <p>{`Building dependencies for Lambda can be confusing. They need to be compiled, zipped up, and then made into a layer. We also have to keep the file size for the dependencies under Lambda's limit. Here is how we did that using a Dockerfile.`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://gist.github.com/joelmichael/efe48be65d789c555459067b5a54e42d"
      }}>{`check out the full Dockerfile here`}</a>{`.`}</p>
    <h2>{`Dockerfile details`}</h2>
    <p>{`One question when building the dependencies was which Docker image to use. We first tried using the `}<inlineCode parentName="p">{`amazonlinux`}</inlineCode>{` image, but this actually resulted in some build problems with one of our dependencies. We later found the LambCI images and ended up using `}<inlineCode parentName="p">{`lambci/lambda:build-ruby2.7`}</inlineCode>{`, because we are using Ruby. This worked perfectly for us, and it has the benefit of already having build tools installed, making for faster Docker builds.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Use AWS Lambda ruby2.7 build environment`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`FROM`}</span>{` lambci/lambda`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`build`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`ruby2.7`}</code></pre></div>
    <p>{`AWS Lambda currently has a limit of 250 MB for its dependencies. If you are building significantly larger dependencies, as we were, then you are likely to reach this limit. It is therefore very important to compile your dependencies in a way that reduces file size. By using the `}<inlineCode parentName="p">{`-Os`}</inlineCode>{` compile time option, we were able to reduce the size of our binaries by over 90%.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Optimize compilation for size to try and stay below Lambda's 250 MB limit`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# This reduces filesize by over 90% (!) compared to the default -O2`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ENV`}</span>{` CFLAGS `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-Os"`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ENV`}</span>{` CXXFLAGS $CFLAGS`}</code></pre></div>
    <p>{`While slightly less performant than the default `}<inlineCode parentName="p">{`-O2`}</inlineCode>{`, the massively reduced file size is worth it in this situation.`}</p>
    <p>{`Next up is building Leptonica.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /root

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Leptonica image-reading dependencies`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` yum install `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y libjpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`devel libpng`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`devel libtiff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`devel

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`O http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//www.leptonica.org/source/leptonica`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`1.79.0.tar.gz
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` tar zxvf leptonica`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`1.79.0.tar.gz

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` leptonica`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`1.79.0
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` ./configure `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prefix=/opt
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` make install`}</code></pre></div>
    <p>{`You will need to install the `}<inlineCode parentName="p">{`-devel`}</inlineCode>{` packages when compiling, but won't need the `}<inlineCode parentName="p">{`-devel`}</inlineCode>{` variant when providing the dependencies for runtime.`}</p>
    <p>{`Then Tesseract:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /root

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Optional Tesseract foreign language training dependencies`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# libicu-devel on Yum is of insufficient version (50, 52 is required)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# These are also not really necessary for our usage.`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#RUN yum install -y libicu-devel pango-devel cairo-devel`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`Lo tesseract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`4.1.1.tar.gz \\
  https`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//github.com/tesseract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`ocr/tesseract/archive/4.1.1.tar.gz
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` tar zxvf tesseract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`4.1.1.tar.gz

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` tesseract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`4.1.1
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` ./autogen.sh `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prefix=/opt
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# These ENV vars have to be set or it will not build`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ENV`}</span>{` LEPTONICA_CFLAGS `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`I/opt/include/leptonica
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`ENV`}</span>{` LEPTONICA_LIBS `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`L/opt/lib `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`lleptonica
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` ./configure `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prefix=/opt
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` make install

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# English training data`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /opt/share/tessdata
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`LO https`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//github.com/tesseract`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`ocr/tessdata_best/raw/master/eng.traineddata`}</code></pre></div>
    <p>{`GhostScript was technically installable via RPM, but the amount of dependencies was too great. More on that later. We decided to just compile it with a minimal dependency set.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /root

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`LO \\
  https`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//github.com/ArtifexSoftware/ghostpdl`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`downloads/releases/download/gs952/ghostscript`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`9.52.tar.gz
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` tar zxvf ghostscript`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`9.52.tar.gz

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` ghostscript`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`9.52
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` ./configure `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prefix=/opt
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` make install`}</code></pre></div>
    <p>{`Ironically, we end up installing `}<inlineCode parentName="p">{`ghostscript-devel`}</inlineCode>{` so that ImageMagick can be built. It might be possible to use the prior GhostScript installation here, but this was simple enough for build purposes.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /root

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` yum install `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y ghostscript`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`devel

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` curl `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`Lo ImageMagick`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`7.0.10`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`6.tar.gz \\
  https`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`//github.com/ImageMagick/ImageMagick/archive/7.0.10`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`6.tar.gz
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` tar zxvf ImageMagick`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`7.0.10`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`6.tar.gz

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` ImageMagick`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`7.0.10`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`6
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` ./configure `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`prefix=/opt
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` make install`}</code></pre></div>
    <p>{`We considered using libvips instead of ImageMagick, but it would have added scope to the project. Nonetheless I have some commented out code in the Dockerfile for building libvips in case we decide to switch to it in the future.`}</p>
    <p>{`For Ruby gems, a Gemfile in the same directory that contains all of the gems is all we need.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`source `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://rubygems.org'`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# PDF processing gems`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'simhash2'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'phashion'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'rtesseract'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'mini_magick'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'pdf-reader'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'hexapdf'`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Other gems used in these files`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'procto'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'adamantium'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'concord'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'activesupport'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'~> 6.0.2'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'chronic'`}</span>{`
gem `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'activestorage'`}</span></code></pre></div>
    <p>{`Note that a little bit of trickery is necessary to modify the gem paths for loading in Lambda.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /root

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Phashion dependencies`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Can skip this step because they are already installed above for Leptonica`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#RUN yum install -y libjpeg-devel libpng-devel`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Copy Gemfile from host into container's current directory`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`COPY`}</span>{` Gemfile .

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` bundle config set path vendor/bundle
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` bundle

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Modify directory structure for Lambda load path`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` vendor/bundle
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` mkdir ruby/gems
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` mv ruby/2.* ruby/gems
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` mv ruby /opt
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /root`}</code></pre></div>
    <p>{`Now the RPM packages. I left in the installation for GhostScript and its dependencies from RPM as a comment, mainly so you can see how many packages I had to specify manually.`}</p>
    <p>{`Note that you will have to specify the `}<inlineCode parentName="p">{`x86_64`}</inlineCode>{` variants of these packages when using these tools.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /root

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Install yumdownloader and rpmdev-extract`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` yum install `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y yum`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`utils rpmdevtools

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` mkdir rpms
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` rpms

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Download dependency RPMs`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` yumdownloader libjpeg`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`turbo.x86_64 libpng.x86_64 libtiff.x86_64 \\
  libgomp.x86_64 libwebp.x86_64 jbigkit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`libs.x86_64
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# GhostScript and dependencies`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# To reduce dependencies, we are compiling GhostScript from source instead`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# RUN yumdownloader ghostscript.x86_64 cups-libs.x86_64 fontconfig.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   fontpackages-filesystem freetype.x86_64 ghostscript-fonts jasper-libs.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   lcms2.x86_64 libICE.x86_64 libSM.x86_64 libX11.x86_64 libX11-common \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   libXau.x86_64 libXext.x86_64 libXt.x86_64 libfontenc.x86_64 libxcb.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   poppler-data stix-fonts urw-fonts xorg-x11-font-utils.x86_64 avahi-libs.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   acl.x86_64 audit-libs.x86_64 cracklib.x86_64 cracklib-dicts.x86_64 cryptsetup-libs.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   dbus.x86_64 dbus-libs.x86_64 device-mapper.x86_64 device-mapper-libs.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   elfutils-default-yama-scope elfutils-libs.x86_64 gzip.x86_64 kmod.x86_64 kmod-libs.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   libcap-ng.x86_64 libfdisk.x86_64 libpwquality.x86_64 libsemanage.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   libsmartcols.x86_64 libutempter.x86_64 lz4.x86_64 pam.x86_64 qrencode-libs.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   shadow-utils.x86_64 systemd.x86_64 systemd-libs.x86_64 ustr.x86_64 util-linux.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   expat.x86_64 xz-libs.x86_64 libgcrypt.x86_64 libgpg-error.x86_64 elfutils-libelf.x86_64 \\`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`#   bzip2-libs.x86_64`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Extract RPMs`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` rpmdev`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`extract *.rpm
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` rm *.rpm

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Copy all package files into /opt/rpms`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` cp `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`vR */usr/* /opt

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# The x86_64 packages extract as lib64, we need to move these files to lib`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` yum install `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y rsync
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` rsync `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`av /opt/lib64/ /opt/lib/
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`r /opt/lib64`}</code></pre></div>
    <p>{`Notice some more path management. We used `}<inlineCode parentName="p">{`rsync`}</inlineCode>{` for copying because `}<inlineCode parentName="p">{`cp`}</inlineCode>{` gave us some problems.`}</p>
    <p>{`Now we just need to zip up the dependencies.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`WORKDIR`}</span>{` /opt
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`RUN`}</span>{` zip `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`r /root/ProcessDocumentLayer.zip *`}</code></pre></div>
    <p>{`And lastly, the entrypoint for the Dockerfile, which copies the zip file to an output directory.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`ENTRYPOINT`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/bin/cp"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/root/ProcessDocumentLayer.zip"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/output"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`Now we just need the Docker commands to build this. I put them at the very top of the file under a "Usage" section.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "dockerfile"
    }}><pre parentName="div" {...{
        "className": "language-dockerfile"
      }}><code parentName="pre" {...{
          "className": "language-dockerfile"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Usage:`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# docker build -t lambda .`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# docker run -v $(pwd):/output lambda`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ./publish_layer.sh.`}</span></code></pre></div>
    <p>{`The `}<inlineCode parentName="p">{`publish_layer.sh`}</inlineCode>{` script is a small one we wrote that uses `}<inlineCode parentName="p">{`awscli`}</inlineCode>{` to upload and publish the layer. You'll have to authenticate with AWS for it to work. I used `}<inlineCode parentName="p">{`aws configure`}</inlineCode>{` for this purpose but you can `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-welcome.html"
      }}>{`check out this article`}</a>{` for more info.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "sh"
    }}><pre parentName="div" {...{
        "className": "language-sh"
      }}><code parentName="pre" {...{
          "className": "language-sh"
        }}>{`#!/bin/sh
aws s3 cp ProcessDocumentLayer.zip s3://process-document-layers
aws lambda publish-layer-version --layer-name ProcessDocumentLayer --description "Process Document dependencies" \\
  --content S3Bucket=process-document-layers,S3Key=ProcessDocumentLayer.zip --compatible-runtimes ruby2.7`}</code></pre></div>
    <p>{`And that's it. With this Dockerfile, we are able to easily build and publish a dependency layer for our OCR system on Lambda. We hope this was useful for you!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      